import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire Webflow Developer`,
    text: "Get Result-Focused Webflow Specialist",
    content: "Hire webflow developer from Octet to transform your company's websites into high-revenue-generating platforms.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire Webflow Developer',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Webflow Expert to Make a Difference',
    text: "Our webflow developers for hire are prepared to build websites that will expand and yield quantifiable outcomes.",
    list: [
      {
        title: 'Enhance the User Experience',
        text: 'Our webflow developers for hire understand how to structure material, develop user-friendly websites, and use responsive design to guarantee that the websites function effortlessly across all platforms.'
      },
      {
        title: 'Performance Optimization',
        text: 'Our webflow experts create websites with clean code, fast loading speeds, and responsiveness, essential for search engine ranking.'
      },
      {
        title: 'Ongoing Support',
        text: 'Our webflow specialist offers retainer packages for ongoing support, website updates, and strategic advice to help you maximize webflow site’s potential.'
      }
    ],
    image: ["hire-content-webflow.webp"],
    titleText: 'Webflow developers',
    alt: 'Hiring webflow developers from Octet will make a difference'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top Webflow Developers for Hire',
    text: "Leading companies trust Octet Design Studio to Hire webflow developers to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire Webflow Expert In 48 Hours',
        text: 'Our top webflow developers for hire ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our webflow experts to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our webflow specialist for hire commits 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire webflow expert and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-webflow.webp"],
    titleText: 'Hire webflow developers',
    alt: 'Benefits of hiring webflow experts from us'
  }
  const commonContent3 = {
    title: `3 Steps to Hire Webflow Specialist`,
    text: "Experience the Octet advantage by hiring webflow experts in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our webflow specialist who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled webflow developers to choose the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire webflow developers, onboard them in the next 48 hours and dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-webflow.webp"],
    titleText: 'Get dedicated webflow developers',
    alt: 'Simple steps to hire webflow developers from Octet'
  }
  const cta = {
    title: `Let’s Make your Webflow Project Easy`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce Webflow Experts",
      content: "Our webflow experts use various tools and software to develop websites that meet users' needs and help convert visitors into customers. Hire webflow developers to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "Webflow Specialist for SaaS",
      content:
        "SaaS webflow developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire webflow expert who will be a creative problem solver and develop beautiful and functional websites that enhance the user experience.",
    },
    {
      title: "Enterprise Webflow Developer",
      content: "Hire webflow developer who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our webflow expert for hire will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire Webflow Developer for Strategic Solutions`,
    des: `Our Webflow experts are proficient at building powerful Webflow websites. Hire webflow expert to get end-to-end Webflow development services to make website building more accessible for everyone.`,
    tabs: ["Static Development ", "Dynamic Development", "E-commerce Development", "CMS Development"],
    slides: [
      {
        title: 'Static Development',
        desc1: "Our webflow expert for hire creates wireframes and mockups for the website using tools such as Figma or Sketch.",
        desc2: 'Hire webflow developers to ensure your website is responsive and works properly across all devices and browsers.',
        list: [
          'Delivering Maximum Functionality',
          'Experienced in Custom HTML',
          'Expertise in Cross-Platform Accessibility'
        ]
      },
      {
        title: 'Dynamic Development',
        desc1: "Hire webflow developer to create designs that account for dynamic elements, ensuring a consistent user experience.",
        desc2: 'Our webflow experts implement webflow interactions to create engaging user experiences based on user actions.',
        list: [
          'Proficient in Website Architecture',
          'Expertise in Dynamic Templates',
          'Providing Cross-Platform Interactions'
        ]
      },
      {
        title: 'E-commerce Development',
        desc1: "Our webflow specialist plans the e-commerce functionality, including product pages, cart, checkout, and user accounts.",
        desc2: 'Hire webflow expert to develop the website focusing on user experience and conversion optimization.',
        list: [
          'Expertise in Custom Code',
          'Delivering Deployment Support',
          'Providing Thorough Testing'
        ]
      },
      {
        title: 'CMS Development',
        desc1: "Hire our webflow experts to build CMS-driven templates that dynamically display content.",
        desc2: ' Our webflow experts for hire test the CMS functionality to ensure content can be easily updated and displayed correctly.',
        list: [
          'Expertise in Creating CMS Collections',
          'Delivering User-friendly Designs',
          'Quick Turn-around Time'
        ]
      }
    ],
    altOne: 'Static Development',
    titleOne: 'Static Development',
    altTwo: 'Dynamic Development',
    titleTwo: 'Dynamic Development',
    altThree: 'E-commerce Development',
    titleThree: 'E-commerce Development',
    altFour: 'CMS Development',
    titleFour: 'CMS Development',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Our webflow developers for hire are intensely passionate and committed to developing captivating websites and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'Webflow Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire Webflow Developers from Octet?",
    "How can you Hire Webflow Expert from Octet?",
    "Which companies Hire Webflow Developer?",
    "Does your Webflow Developers for Hire come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of webflow experts who specialize in crafting engaging and functional interfaces.`,
        `Our webflow expert for hire has a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `You should hire webflow expert from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring webflow developers from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable webflow specialist to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of webflow specialist and developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring webflow experts from us, onboard our designers onto your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `Webflow developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile app, building websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring webflow experts from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring webflow developer from us, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Webflow Developers for Effortless Web Development",
    para: 'To deliver outcomes that significantly impact businesses, our skilled team of webflow experts provides a variety of web design services.',
    content: [
      {
        title: "User Research",
        text: "Hire webflow developer who can closely examine the behavior of your target market in order to achieve steady growth. Our webflow expert for hire delivers user-friendly and efficient solutions by learning about their preferences and routines.",
      },
      {
        title: "Custom Web Design",
        text: "Hire webflow experts to create unique, visually appealing website designs that align with your brand’s identity. Our webflow specialist understands your brand, audience, and goals to develop a site that stands out and effectively communicates your message.",
      },
      {
        title: "Webflow Development",
        text: "Our webflow developers for hire use Webflow’s tools to create custom animations, interactions, and dynamic elements. This includes integrating third-party services and ensuring the website is scalable and maintainable.",
      },
      {
        title: "Conversion Rate Optimization",
        text: "Our webflow experts analyze user behavior and implement strategies like user journey mapping, and improving call-to-actions to boost conversions for sales, sign-ups, or other objectives.",
      },
      {
        title: "UI UX Design",
        text: "Our webflow developers main goal is to create digital experiences for a variety of sectors. Hire a webflow expert to comprehend your target market and acquire knowledge to get tailored UI UX services that will enhance business performance.",
      },
      {
        title: "Prototyping and Wireframing",
        text: "Hire webflow expert to develop visual representations of the website’s structure and layout before full-scale development. Our webflow developers for hire help visualize the end product and make necessary adjustments early in the development process.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire Webflow Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of webflow experts who specialize in crafting engaging and functional interfaces. 
          
          Our webflow expert for hire has a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          You should hire webflow expert from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire Webflow Expert from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring webflow developers from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable webflow specialist to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of webflow specialist and developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring webflow experts from us, onboard our designers onto your project team and provide them access to necessary resources.
          
          Which companies Hire Webflow Developer?
          Webflow developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile app, building websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire Webflow Developer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Webflow developers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile app, building websites, software interfaces, or consumer electronics, our app designers for hire can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does your Webflow Developers for Hire come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring webflow experts from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring webflow developer from us, you can trust that you're getting the most value for your money."
              }
            }]
          }                                  
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Webflow Developer">
        <div>
          <HireBanner data={banner} pageName="Hire Webflow Developer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Industry's Top Web Expert for Hire`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our webflow experts, hiring webflow developers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire Webflow Developer | Webflow Expert for Hire"
    description="Hire webflow developer to tackle the Webflow project for you. Our webflow specialist has developed, designed, and supported webflows for many years."
  />
);